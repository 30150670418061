<template>
  <a-layout class="transaction-list">
    <TitleBreadcrumb />
    <div class="content-base container">
      <a-card class="transaction-list-card">
        <a-row
          type="flex"
          justify="center"
          :class="{ 'flex-direction-reverse': isMobile }"
          :gutter="[16, 16]"
        >
          <a-col :xs="24" :sm="24" :md="19">
            <a-input-search
              :placeholder="labels.placeholderSearch"
              style="width: 100%"
              @search="handleSearch"
              allowClear
            />
          </a-col>
          <a-col :xs="24" :sm="24" :md="5">
            <router-link :to="{ name: 'transaction-request' }">
              <a-button
                class="btn-new-transaction"
                icon="plus"
                :style="{ width: '100%' }"
              >
                {{ labels.newTransaction }}
              </a-button>
            </router-link>
          </a-col>
        </a-row>
        <a-divider
          orientation="center"
          type="horizontal"
          :style="{ marginBottom: 0 }"
        />
        <a-row type="flex" justify="center" :gutter="[16, 16]">
          <a-col :xs="24">
            <StandardTable
              class="table-base"
              ref="standardTable"
              :fetchService="fetchClientTransactions"
              :columns="columns"
              :locale="locale"
              :handleShowTotal="handleShowTotal"
              :scroll="{ x: isMobile ? true : false }"
              :rowKey="'code'"
              :paginationType="isMobile ? 'arrows' : 'numbers'"
              :customRow="handleCustomRow"
            />
          </a-col>
        </a-row>
      </a-card>
    </div>
  </a-layout>
</template>
<script>
import { mapActions, mapState } from "vuex";
import accounting from "accounting";
import TitleBreadcrumb from "@/components/layout/client/TitleBreadcrumb.vue";
import loadingMixin from "@/mixins/loadingMixin";
import { pageTitle } from "@/utils/utils";
import labels from "@/utils/labels";
import StandardTable from "@/components/core/table/StandardTable.vue";
import { DATE_FORMAT } from "../../../utils/utils";
export default {
  components: { TitleBreadcrumb, StandardTable },
  name: "transactionList",
  mixins: [loadingMixin],
  metaInfo: {
    title: pageTitle(labels.transactionList.subtitle),
    meta: [
      {
        name: "title",
        content: labels.appName,
      },
      {
        name: "description",
        content: labels.transactionList.subtitle,
      },
    ],
  },
  data() {
    return {
      labels: labels.transactionList,
      search: "",
      activeRow: null,
    };
  },
  methods: {
    ...mapActions("transactions", [
      "fetchClientTransactions",
      "cancelTransfer",
    ]),
    handleReloadTable() {
      let filtersTable = this.$refs.standardTable.filters;
      let result = { ...filtersTable };
      this.$refs.standardTable.handleFilters(result);
    },
    handleShowTotal(total, range) {
      return `${range[0]}-${range[1]} de ${total}`;
    },
    handleSearch(search) {
      this.$refs.standardTable.handleSearch(search);
    },
    confirmCancellation(record) {
      this.$confirm({
        title: this.labels.confirmCancellation.title,
        content: this.labels.confirmCancellation.content.replace(
          "{code}",
          record.code
        ),
        okText: this.labels.confirmCancellation.okText,
        okType: "danger",
        cancelText: this.labels.confirmCancellation.cancelText,
        onOk: () => {
          this.handleCancelTransfer(record);
        },
      });
    },
    async handleCancelTransfer(record) {
      this.loading = true;
      try {
        await this.cancelTransfer(record.code);
        this.$message.success(this.labels.cancelTransferSuccess);
        this.handleReloadTable();
        this.checkSession();
      } catch (error) {
        this.$message.error(this.labels.cancelTransferError);
      } finally {
        this.loading = false;
      }
    },
    handleCustomRow(_, index) {
      return {
        on: {
          click: () => {
            this.activeRow = this.activeRow === index ? null : index;
          },
        },
      };
    },
    handleShowMoreInfo(record, event) {
      event.stopPropagation();
      if (record?.code) {
        this.$info({
          title: this.labels.moreInfo.title.replace(
            "{code}",
            record?.code || "-"
          ),
          class: "more-info-modal",
          content: (
            <div>
              <p>
                <strong>{this.labels.moreInfo.date}:</strong>{" "}
                {this.$moment(record?.date).format(
                  DATE_FORMAT.MOMENT_DATE_TIME
                )}
              </p>
              <p>
                <strong>{this.labels.moreInfo.code}:</strong>{" "}
                {record?.code || "-"}
              </p>
              <p>
                <strong>{this.labels.moreInfo.reference}:</strong>{" "}
                {record?.ticket?.number || record?.reference || "-"}
              </p>
              <p>
                <strong>{this.labels.moreInfo.type}:</strong>{" "}
                {record?.type?.name || "-"}
              </p>
              <p>
                <strong>{this.labels.moreInfo.origin}:</strong>{" "}
                {record?.origin?.name || "-"}
              </p>
              <p>
                <strong>{this.labels.moreInfo.amount}:</strong>{" "}
                {accounting.formatNumber(record?.amount, 2, ",", ".")}
              </p>
              <p>
                <strong>{this.labels.moreInfo.status}:</strong>{" "}
                {this.statusText(record?.status?.toLowerCase())}
              </p>
            </div>
          ),
        });
      }
    },
  },
  computed: {
    ...mapState("layout", ["screenWidth"]),
    locale() {
      return {
        emptyText: this.labels.table.emptyText,
      };
    },
    columns() {
      return [
        {
          title: this.labels.table.columns.date,
          dataIndex: "date",
          key: "date",
          sorter: true,
          hidden: this.isMobile,
          customRender: (text) => {
            return this.$moment(text).format(DATE_FORMAT.MOMENT_DATE_TIME);
          },
        },
        {
          title: this.labels.table.columns.code,
          dataIndex: "code",
          key: "code",
          sorter: true,
          hidden: this.isMobile,
        },
        {
          title: this.labels.table.columns.type,
          dataIndex: "type",
          key: "type",
          sorter: true,
          hidden: this.isMobile,
          customRender: (text) => {
            return text?.name;
          },
        },
        {
          title: this.labels.table.columns.origin,
          dataIndex: "origin",
          key: "origin",
          sorter: true,
          hidden: this.isMobile,
          customRender: (text) => {
            return text?.name;
          },
        },
        {
          title: this.labels.table.columns.amount,
          dataIndex: "amount",
          key: "amount",
          sorter: true,
          hidden: this.isMobile,
          customRender: (text) => {
            return accounting.formatNumber(text || 0, 2, ",", ".");
          },
        },
        {
          title: this.labels.table.columns.status.title,
          dataIndex: "status",
          key: "status",
          sorter: true,
          hidden: this.isMobile,
          customRender: (text, record) => {
            const tooltip = (component) => {
              return (
                <span>
                  {component}
                  <a-tooltip trigger="click" title={record.reject_reason}>
                    <a-icon type="info-circle" class="text-danger" />
                  </a-tooltip>
                </span>
              );
            };
            const status = (
              <a-tag color={this.statusColor(text?.toLowerCase())}>
                {this.statusText(text?.toLowerCase())}
              </a-tag>
            );
            return record?.reject_reason && record?.status === "REJECTED"
              ? tooltip(status)
              : status;
          },
        },
        {
          title: this.labels.table.columns.action.title,
          dataIndex: "action",
          key: "action",
          width: "120px",
          align: "center",
          hidden: this.isMobile,
          customRender: (_, record) => {
            return (
              <div class="action-buttons">
                <router-link
                  to={{
                    name: "transaction-view",
                    params: { code: record.code },
                  }}
                  title={this.labels.table.columns.action.view}
                >
                  <a-button icon="eye" class="action-button-view" />
                </router-link>
                {record?.is_cancellable && <a-divider type="vertical" />}
                {record?.is_cancellable && (
                  <a-button
                    onClick={() => this.confirmCancellation(record)}
                    type="danger"
                    icon="delete"
                  />
                )}
              </div>
            );
          },
        },
        {
          title: this.labels.table.columns.transactions,
          dataIndex: "transactions",
          key: "transactions",
          sorter: false,
          hidden: !this.isMobile,
          customRender: (_, record, index) => {
            // Card item design
            const color = this.statusColor(record.status?.toLowerCase());
            const tooltip = (component) => {
              return (
                <span>
                  {component}
                  <a-tooltip
                    trigger="click"
                    onClick={(event) => event.stopPropagation()}
                    title={record.reject_reason}
                  >
                    <a-icon type="info-circle" class="text-danger" />
                  </a-tooltip>
                </span>
              );
            };
            const status = (
              <a-tag color={this.statusColor(record.status?.toLowerCase())}>
                {this.statusText(record.status?.toLowerCase())}
              </a-tag>
            );
            return (
              <div class="list-item">
                <div class="list-item-head">
                  <div
                    class="list-item-head-header"
                    style={{ borderColor: color }}
                  >
                    <span>
                      {this.$options.filters.ucwords(
                        this.$moment(record.date).format(
                          DATE_FORMAT.MOMENT_DAY_MONTH
                        )
                      )}
                    </span>
                    <small>
                      {this.$moment(record.date)
                        .format(DATE_FORMAT.MOMENT_TIME)
                        .toUpperCase()}
                    </small>
                  </div>
                  <div class="list-item-head-content">
                    <div class="list-item-head-content-number">
                      <span>{record.code}</span>
                    </div>
                    <div class="list-item-head-content-type">
                      <span>{record.type?.name}</span>
                    </div>
                    <div class="list-item-head-content-origin">
                      <span>{record.origin?.name}</span>
                    </div>
                  </div>
                  <div class="list-item-head-footer">
                    <div class="list-item-head-footer-amount">
                      <span>
                        {accounting.formatNumber(record.amount, 2, ",", ".")}
                      </span>
                    </div>
                    <div class="list-item-head-footer-status">
                      {record?.reject_reason && record?.status === "REJECTED"
                        ? tooltip(status)
                        : status}
                    </div>
                  </div>
                </div>
                {this.activeRow === index && (
                  <div class="list-item-actions">
                    <a-button-group>
                      <a-button
                        type="primary"
                        class="more-info-button"
                        title={this.labels.table.columns.action.moreInfo}
                        ghost
                        onClick={(event) =>
                          this.handleShowMoreInfo(record, event)
                        }
                      >
                        {this.labels.table.columns.action.moreInfo}
                      </a-button>
                      <router-link
                        class="view-button ant-btn ant-btn-primary ant-btn-block"
                        to={{
                          name: "transaction-view",
                          params: { code: record.code },
                        }}
                        title={this.labels.table.columns.action.view}
                      >
                        {this.labels.table.columns.action.view}
                      </router-link>
                      {record?.is_cancellable && <a-divider type="vertical" />}
                      {record?.is_cancellable && (
                        <a-button
                          onClick={() => this.confirmCancellation(record)}
                          type="danger"
                          class="cancel-button ant-btn ant-btn-danger"
                        >
                          {this.labels.table.columns.action.cancel}
                        </a-button>
                      )}
                    </a-button-group>
                  </div>
                )}
              </div>
            );
          },
        },
      ].filter((column) => !column?.hidden);
    },
  },
};
</script>
<style lang="scss">
@import "~@/assets/scss/variable";
.transaction-list {
  max-width: 100% !important;
  font-family: "Roboto", sans-serif;
  background-color: transparent;
  @media screen and (max-width: $screen-mobile) {
    max-width: 100% !important;
  }
  .content-base {
    padding-top: 60px;
    @media screen and (max-width: $screen-mobile) {
      max-width: 100% !important;
    }
  }
  &-card {
    background-color: color(c-secondary);
    border-color: color(c-secondary);
    padding: 1.125rem;
    border-radius: 10px;
    @media screen and (max-width: $screen-mobile) {
      padding: 2.125rem 1rem;
      width: 100%;
    }
    .ant-input {
      box-shadow: 0 1px 4px rgba(255, 255, 255, 0.05) !important;
      border: 1px solid color(--white) !important;
      color: color(--white) !important;
      font-family: "Exo 2", sans-serif !important;
      background-color: transparent;
    }
    .ant-input-search-icon {
      color: color(--white) !important;
      transition: all 0.5s ease;
      :hover {
        color: color(c-hover) !important;
        transition: all 0.5s ease;
      }
    }
    .ant-input-clear-icon {
      color: color(--white) !important;
      transition: all 0.5s ease;
      :hover {
        color: color(danger) !important;
        transition: all 0.5s ease;
      }
    }
    .ant-table {
      &:hover {
        transition: 0.5s ease;
        background: unset;
      }
      tr.ant-table-row:hover {
        transition: 0.5s ease;
        background: rgba(0, 0, 0, 0.25) !important;
      }
      tr:nth-child(2n) {
        transition: 0.5s ease;
        background: rgba(0, 0, 0, 0.2) !important;
      }
      tr.table-row-disabled,
      tr.table-row-disabled:hover {
        color: color(c-primary);
        background: color(danger-light) !important;
        font-weight: 600;
      }
      .ant-table-tbody > tr.ant-table-row:hover > td {
        transition: 0.5s ease;
        background: none !important;
      }
      &-tbody,
      &-thead {
        color: color(--white) !important;
        tr {
          th {
            color: color(--white) !important;
            background-color: transparent !important;
          }
        }
      }
      &-footer {
        background: transparent;
      }
      .action-button-view {
        color: color(c-hover);
        border-color: color(c-hover);
        transition: all 0.5s ease;
        background-color: color(c-secondary);
        &:hover {
          transition: all 0.5s ease;
          color: color(c-secondary);
          background-color: color(c-hover);
          border-color: color(c-hover);
        }
      }
      .ant-table-placeholder {
        background: color(c-secondary);
        color: color(--white);
        border-radius: 0;
      }
      @media screen and (max-width: $screen-mobile) {
        &-small {
          border: none;
        }
        &-body {
          margin: 0 1px;
        }
      }
    }
    .ant-pagination-prev .ant-pagination-item-link,
    .ant-pagination-next .ant-pagination-item-link {
      color: color(c-secondary) !important;
      background-color: color(c-hover) !important;
      border-color: color(c-hover) !important;
    }
    .ant-pagination-item {
      background-color: color(c-hover) !important;
      border-color: color(c-secondary) !important;
      a {
        color: color(c-secondary) !important;
      }
      &-active {
        background-color: color(c-secondary) !important;
        border-color: color(c-hover) !important;
        a {
          color: color(c-hover) !important;
        }
      }
    }
    .ant-pagination {
      color: color(--white) !important;
    }
    .btn-new-transaction {
      color: color(c-hover);
      border-color: color(c-hover);
      transition: all 0.5s ease;
      background-color: color(c-primary);
      &:hover {
        transition: all 0.5s ease;
        color: color(c-primary);
        background-color: color(c-hover);
        border-color: color(c-hover);
      }
    }
    .flex-direction-reverse {
      flex-direction: column-reverse;
    }
  }
  .text-danger {
    color: color(danger) !important;
  }
  .list-item {
    display: flex;
    flex-direction: column;
    gap: 4px;
    &-head {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: 6px;
      &-header {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 2px;
        max-width: 22%;
        border-right: 4px solid color(border);
        .success {
          color: color(success);
        }
        .warning {
          color: color(warning);
        }
        .danger {
          color: color(danger);
        }
        span {
          font-size: 14px;
          font-weight: 600;
        }
        small {
          font-size: 12px;
          font-weight: 400;
          padding-right: 4px;
        }
      }
      &-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 2px;
        max-width: 53%;
        overflow: hidden;
        &-number {
          span {
            font-size: 14px;
            font-weight: 600;
            text-overflow: ellipsis;
          }
        }
        &-type {
          span {
            font-size: 12px;
            font-weight: 400;
            text-overflow: ellipsis;
          }
        }
        &-origin {
          span {
            font-size: 12px;
            font-weight: 400;
            text-overflow: ellipsis;
          }
        }
      }
      &-footer {
        max-width: 25%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 2px;
        &-amount {
          span {
            font-size: 14px;
            font-weight: 600;
          }
        }
        &-status {
          span {
            font-size: 12px;
            font-weight: 400;
          }
          .ant-tag {
            padding: 0;
            margin: 0;
            padding-left: 2px;
            padding-right: 2px;
          }
        }
      }
    }
    &-actions {
      width: 100%;
      margin-top: 10px;
      .ant-btn-group {
        width: 100%;
        .more-info-button,
        .view-button,
        .cancel-button {
          width: 100%;
        }
        .more-info-button {
          background-color: transparent;
          border-color: color(c-hover);
          color: color(c-hover);
          &:active,
          &:focus,
          &:hover {
            background-color: transparent;
            border-color: color(c-hover);
            color: color(c-hover);
          }
        }
        .view-button {
          background-color: color(c-hover);
          border-color: color(c-hover);
          color: color(c-primary);
          &:active,
          &:focus,
          &:hover {
            background-color: color(c-hover);
            border-color: color(c-hover);
            color: color(c-primary);
          }
        }
      }
    }
  }
}
.more-info-modal {
  .ant-modal-content {
    background-color: color(c-primary);
  }
  .ant-modal-confirm-body {
    .ant-modal-confirm-title {
      color: color(c-hover);
    }
    .anticon {
      color: color(c-hover);
    }
    .ant-modal-confirm-content {
      color: color(--white);
    }
  }
  .ant-modal-confirm-btns {
    .ant-btn {
      background-color: color(c-hover);
      border-color: color(c-hover);
      color: color(c-primary);
      &:active,
      &:focus,
      &:hover {
        background-color: color(c-hover);
        border-color: color(c-hover);
        color: color(c-primary);
      }
    }
  }
}
</style>
